<template>
    <div class="d-flex align-center fs-14">
        <div class="h2 fw-500">{{ value }}{{ postfix }}</div>
        <span v-if="hasCompareValue" class="fw-400 fs-18 ml-2"><span :class="`${diffColor}--text`">{{valuesDiff}}%</span></span>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: [String, Number], required: true },
        compareValue: { type: Number, default: null },
        postfix: { type: String, default: '' }
    },
    computed: {
        hasCompareValue () {
            return Number.isInteger(this.compareValue) && Number.isFinite(this.compareValue)
        },
        valuesDiff () {
            if (!this.hasCompareValue)
                return 0

            const value = typeof this.value === 'number' ? this.value : 0

            if (this.compareValue === value)
                return 0

            if (this.compareValue === 0)
                return 100

            return Math.round(((value / this.compareValue) * 100) - 100)
        },
        diffColor () {
            if (this.valuesDiff > 0)
                return 'green'
            else if (this.valuesDiff < 0)
                return 'red'
            else
                return 'black'
        }
    }
}
</script>