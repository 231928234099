<template>
    <div>
        <div class="d-flex">
            <v-spacer />
            <v-btn
                outlined
                height="40px"
                class="button-stylized-blue"
                :loading="loading.export"
                @click.prevent.stop="exportToXlsx"
            >
                Экспорт в xlsx
            </v-btn>
        </div>

        <template v-for="(groupsInParallel, grade) in reportData">
            <h4 :key="`header_${grade}`" class="mb-2">Классы {{ grade }}</h4>

            <div :key="`results_${grade}`" class="d-flex flex-wrap">
                <v-card
                    v-for="group in groupsInParallel"
                    :key="`card_${group.group_id}`"
                    elevation="0"
                    class="border-1 w-32 mb-5 mr-3"
                    style="width: max-content"
                >
                    <div class="h2 fw-500 mb-4">Оценки {{ group.name }}</div>
                    <div class="d-flex flex-column">
                        <span class="d-flex fs-14 align-center">
                            <span class="color-font-default fs-12 fw-400 opacity-60">Минимальная</span>
                            <v-spacer />
                            <value-cell
                                :value="group.min.value"
                                :compare-value="group.min.prevValue"
                                class="ml-2"
                            />
                        </span>
                        <v-divider class="my-1" />
                        <span class="d-flex fs-14 align-center">
                            <span class="color-font-default fs-12 fw-400 opacity-60">Средняя</span>
                            <v-spacer />
                            <value-cell
                                :value="group.avarage.value"
                                :compare-value="group.avarage.prevValue"
                                class="ml-2"
                            />
                        </span>
                        <v-divider class="my-1" />
                        <span class="d-flex fs-14 align-center">
                            <span class="color-font-default fs-12 fw-400 opacity-60">Максимальная</span>
                            <v-spacer />
                            <value-cell
                                :value="group.max.value"
                                :compare-value="group.max.prevValue"
                                class="ml-2"
                            />
                        </span>
                    </div>
                </v-card>
            </div>
        </template>
    </div>
</template>

<script>
import ValueCell from './ValueCell.vue'
import ExcelHelper from '@/helpers/ExcelHelper'
import { saveDataAsFile } from '@/helpers/File'

export default {
    components: {
        ValueCell
    },
    props: {
        results: { type: Array, required: true },
        prevResults: { type: Array, required: true },
        exportFileName: { type: String, default: 'Статистика по предметам' }
    },
    data () {
        return {
            loading: {
                export: false
            }
        }
    },
    computed: {
        gradesInResults () {
            return Array.from(new Set(this.results.map(group => group.grade)))
        },
        reportData () {
            const result = {}

            this.gradesInResults.forEach(grade => {
                if (!Array.isArray(result[grade]))
                    result[grade] = []
                
                const groups = _.cloneDeep(this.results)
                                    .filter(group => group.grade === grade)
                                    .map(group => {
                                        group.avarage = {
                                            value: this.getAvarageScore(group.items.map(student => student.scores).flat()),
                                            prevValue: this.getAvarageScore(this.getScoresOfGroupFromResults(this.prevResults, group.group_id))
                                        }
                                        group.max = {
                                            value: this.getMaxScore(group.items.map(student => student.scores).flat()),
                                            prevValue: this.getMaxScore(this.getScoresOfGroupFromResults(this.prevResults, group.group_id))
                                        }
                                        group.min = {
                                            value: this.getMinScore(group.items.map(student => student.scores).flat()),
                                            prevValue: this.getMinScore(this.getScoresOfGroupFromResults(this.prevResults, group.group_id))
                                        }
                                        return group
                                    })

                groups.sort((a, b) => {
                            if (a.name < b.name) return -1
                            if (a.name > b.name) return 1
                            return 0
                        })

                result[grade].push(...groups)
            })
            return result
        }
    },
    methods: {
        getAvarageScore (scores) {
            const sum = scores.reduce((a, b) => a + b, 0)
            const avg = (sum / scores.length) || 0
            const result = parseFloat(avg.toFixed(2))
            return Number.isFinite(result) ? result : 0
        },
        getMaxScore (scores) {
            const result = Math.max(...scores)
            return Number.isFinite(result) ? result : 0
        },
        getMinScore (scores) {
            const result = Math.min(...scores)
            return Number.isFinite(result) ? result : 0
        },
        getScoresOfGroupFromResults (prevResults, group_id) {
            return prevResults.find(group => group.group_id === group_id)?.items.map(student => student.scores).flat() || []
        },
        async exportToXlsx () {
            
            this.loading.export = true

            try {
                const workbook = ExcelHelper.createWorkbook()
                const worksheet = ExcelHelper.createWorksheet(workbook, 'Статистика по предметам')

                Object.keys(this.reportData).forEach(grade => {
                    
                    this.reportData[grade].forEach((group, index) => {
                        
                        if (index === 0) {
                            worksheet.addRow([`Классы ${grade}`])
                            worksheet.lastRow.eachCell({}, function(cell) {
                                cell.font = { bold: true }
                            })
                        }
                        worksheet.addRow()
                        worksheet.addRow([`Оценки ${group.name}`])
                        worksheet.addRow(['Средняя', 'Максимальная', 'Минимальная'])

                        worksheet.addRow([group.avarage.value, group.max.value, group.min.value])
                    })
                })

                worksheet.columns.forEach(column => {
                    column.width = 20

                    column.eachCell({ includeEmpty: true }, function(cell) {
                        cell.border = {
                            top: {style: 'thin'},
                            left: {style: 'thin'},
                            bottom: {style: 'thin'},
                            right: {style: 'thin'}
                        }
                    })
                })

                const xlsxData = await workbook.xlsx.writeBuffer()
                const blob = typeof xlsxData === Blob ? xlsxData : new Blob([xlsxData], { type: 'application/xlsx' })
                saveDataAsFile(
                    blob,
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    `${this.exportFileName}.xlsx`
                )
            } catch (e) {
                console.error(e)
                this.$root.$emit('snack-bar', { text: 'Формирование файла прервано ошибкой' })
            } finally {
                this.loading.export = false
            }
        }
    }
}
</script>