<template>
    <div class="action-buttons-component w-100 d-flex align-center" :style="{ transform: `translateY(${offsetY}px)`}">
        <v-btn
            v-if="!hideCreateBtn"
            outlined
            height="40px"
            class="button-stylized-light-blue"
            :disabled="disabledCreateBtn"
            @click="$emit('click:createReport')"
        >
            <icon
                name="send"
                :color="disabledCreateBtn ? $const.color.grey : $const.color.primary"
                class="mr-2"
            />
            Добавить отчет по классу
        </v-btn>

        <v-spacer />

        <a
            class="fs-14 d-flex align-center mr-6"
            :class="[createBtnLoadingState || !allSelectedReportsHasDoneStatus ? 'color-font-grey untouchable' : 'color-primary' ]"
            @click="$emit('click:preview')"
        >
            <icon name="eye" :color="createBtnLoadingState || !allSelectedReportsHasDoneStatus ? $const.color.grey : $const.color.primary" class="mr-2"/>
            Предпросмотр
        </a>


        <a
            :disabled="!allSelectedReportsHasDoneStatus"
            :class="[allSelectedReportsHasDoneStatus ? 'color-primary' : 'color-font-grey untouchable']"
            class="fs-14 d-flex align-center"
            @click="dialogs.exportReport = true"
        >
            <icon name="export" :color="allSelectedReportsHasDoneStatus ? $const.color.primary : $const.color.grey" class="mr-2"/>
            Экспорт отчёта
        </a>


        <custom-dialog
            v-model="dialogs.exportReport"
            :persistent="createBtnLoadingState"
            title="Экспорт отчёта"
    >
                <div class="d-flex align-center justify-center w-100 my-5">
                    <v-btn
                        :disabled="createBtnLoadingState"
                        outlined
                        height="40px"
                        class="button-stylized mr-4"
                        @click.prevent.stop="$emit('click:createResult', 'json')"
                    >
                        Отчёт для передачи в ФИС ОКО
                    </v-btn>
                    <v-btn
                        :loading="createBtnLoadingState"
                        outlined
                        height="40px"
                        class="button-stylized"
                        @click.prevent.stop="$emit('click:createResult', 'xlsx')"
                    >
                        Отчёт в формате XlSX
                    </v-btn>
                </div>
        </custom-dialog>
    </div>
</template>

<script>
import Icon from '@/components/icons/Index.vue'
import CustomDialog from '@/components/template/CustomDialog.vue'
import adjustToScreenBottomMixin from '@/mixins/adjustToScreenBottomMixin'

export default {
    mixins: [adjustToScreenBottomMixin],
    components: { Icon, CustomDialog },
    props: {
        hideCreateBtn: { type: Boolean, default: false },
        disabledCreateBtn: { type: Boolean, default: false },
        createBtnLoadingState: { type: Boolean, default: false },
        allSelectedReportsHasDoneStatus: { type: Boolean, default: false }
    },
    data () {
        return {
            dialogs: {
                exportReport: false
            }
        }
    },
    watch: {
        createBtnLoadingState (newValue, oldValue) {
            if (oldValue === true && newValue === false)
                this.dialogs.exportReport = false
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables';

.action-buttons-component {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: $application-background;
    will-change: transform;
}

</style>