<template>
    <div>
        <v-btn
            text
            :disabled="disabled"
            class="w-100"
            @click="show"
        >
            <slot>
                <div class="d-flex w-100" style="height: 40px;">
                    <div class="d-flex flex-column justify-space-around text-left w-100" style="text-transform: none; letter-spacing: normal;">
                        <span class="fw-400 fs-12" :style="{ color: $const.color.fontGrey }">{{ label }}</span>
                        <span v-if="hasSomeValue">{{ selectedValueText }}</span>
                    </div>
                    <v-icon :color="$const.color.fontGrey">mdi-menu-down</v-icon>
                </div>
            </slot>
        </v-btn>
        <v-menu
            v-model="showMenu"
            absolute
            offset-y
            :position-x="x"
            :position-y="y"
            :close-on-content-click="false"
            class="w-100"
        >
            <v-card class="w-100">
                <div class="d-flex w-100">
                    <v-list dense style="min-width: 100px;">
                        <v-list-item-group :color="$const.color.primary">
                            <v-list-item
                                v-for="(item, i) in items"
                                :key="i"
                                :disabled="item.disabled"
                                @click="onItemClick(item)"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>{{ typeof item === 'object' ? item.text : item }}</v-list-item-title>
                                    <template v-if="item.subtext">
                                        <v-divider class="my-2" />

                                        <v-subheader style="height: 20px;">{{ item.subtext }}</v-subheader>
                                    </template>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: { type: [Number, String, Array], default: null },
        items: { type: Array, required: true },
        disabled: { type: Boolean, default: false },
        label: { type: String, default: 'Выберите' }
    },
    data () {
        return {
            showMenu: false,
            x: 0,
            y: 0,
        }
    },
    computed: {
        hasSomeValue () {
            return !!(this.value != null && this.value !== undefined && this.selectedValueText)
        },
        selectedValueText () {
            return this.items.find((item) => item.value === this.value)?.text || this.value?.text
        }
    },
    methods: {
        show (e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            })
        },
        onItemClick (item) {
            this.$emit('change', typeof item === 'object' ? item.value : item)
            this.showMenu = false
        }
    }
}
</script>